import MuxEmbed, { useMuxEmbed } from "@pathwright/media-client/src/MuxEmbed"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import { useCallback, useEffect, useRef } from "react"
import LazyVideoPlayer from "../LazyVideoPlayer"
import { embedIsReady, embedNeedsPolling } from "./utils"

const POLL_RATE = 5000

const RenderPollVideo = (props) => {
  const { embed, refetch, onReady } = props

  const pollRef = useRef(null)

  useEffect(() => {
    pollRef.current = setInterval(() => {
      refetch()
    }, POLL_RATE)

    return () => {
      clearInterval(pollRef.current)
      pollRef.current = null
    }
  }, [])

  useEffect(() => {
    if (embedIsReady(embed)) {
      clearInterval(pollRef.current)
      pollRef.current = null
      onReady(embed)
    }
  }, [embed])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        color: "#ccc",
        textAlign: "center"
      }}
    >
      <LoadingCircle center={false} />
      <p style={{ marginTop: "0.5rem" }}>Preparing your video.</p>
    </div>
  )
}

const EmbedPlayer = (props) => {
  const { allowDownload, coverImage, controls } = props

  const { loading, error, embed, refetch } = useMuxEmbed()

  const needsPolling = embedNeedsPolling(embed)

  const subtitleTracks = Object.keys(embed?.subtitles || {})
    .filter((s) => embed?.subtitles[s]?.active)
    .map((s) => embed?.subtitles[s])

  const handleVideoReady = useCallback(() => {
    refetch()
  })

  if (!needsPolling && loading) {
    return <LoadingCircle />
  }

  if (error) return <p>{JSON.stringify(error, null, 2)}</p>

  if (needsPolling) {
    return (
      <RenderPollVideo
        embed={embed}
        refetch={refetch}
        onReady={handleVideoReady}
      />
    )
  }

  const downloadURL = embed?.download?.url || ""

  if (embed?.status === "ready")
    return (
      <LazyVideoPlayer
        src={embed.stream.url}
        showControls={controls}
        coverImage={coverImage || embed.thumb.src}
        width={embed.stream.size.maxWidth}
        height={embed.stream.size.maxHeight}
        downloadURL={downloadURL}
        allowDownload={allowDownload && !!downloadURL}
        subtitleTracks={subtitleTracks}
      />
    )
}

const PlayerWithEmbed = (props) => {
  const { video, allowDownload, coverImage, controls } = props

  return (
    <MuxEmbed id={video.id}>
      <EmbedPlayer
        video={video}
        allowDownload={allowDownload}
        coverImage={coverImage}
        controls={controls}
      />
    </MuxEmbed>
  )
}

export default PlayerWithEmbed
