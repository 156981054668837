// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
const { createCardRoutes } = require("lib/core/routing/card-routing")
import ManageNotificationSettingsCard from "@pathwright/web/src/modules/profile/manage/ManageNotificationSettingsCard"
import { getDefaultLaunchedFromHome } from "../../../lib/core/routing/utils"

const cardRoutes = {
  profile: {
    component: require("./profile/ProfileCard").default,
    getDefaultLaunchedFrom: getDefaultLaunchedFromHome,
    childCards: {
      billing: {
        component: require("./billing/BillingCard").default,
        middleware: (nextState, replace, callback) => {
          // Ensure user has manageable subscription.
          const hasSchoolPlans =
            App.getStore("school").request.hasSubscriptionPlans()
          const redirect = hasSchoolPlans ? null : replace("/manage/profile/")
          return callback(redirect)
        },
        childCards: {
          payment: {
            component: require("./update-payment/UpdatePayment").default
          },
          cancel: {
            component: require("./cancel-subscription/CancelSubscription")
              .default
          },
          // dropped these routes as of 9/3/21 and likely can remove from codebase
          // after some time, just using these redirects for good measure
          subscription: {
            middleware: (nextState, replace) =>
              replace("/manage/profile/billing/"),
            component: () => null,
            childCards: {
              cancel: {
                middleware: (nextState, replace) =>
                  replace("/manage/profile/billing/cancel/"),
                component: () => null
              },
              pause: {
                middleware: (nextState, replace) =>
                  replace("/manage/profile/billing/"),
                component: () => null
              }
            }
          },
          history: {
            component: require("./payment-history/PaymentHistory").default
          }
        }
      },
      password: {
        component: require("./change-password/PasswordCard").default
      },
      history: {
        component: require("./payment-history/PaymentHistory").default
      },
      notifications: {
        component: ManageNotificationSettingsCard
      }
    }
  }
}

const routes = createCardRoutes(
  "/manage/",
  cardRoutes,
  require("home/components/HomeOrLibraryBrowse").default
)

export default routes
