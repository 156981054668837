// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
const User = require("auth/models/user").default
const UpdatePayment =
  require("form/components/payments/models/update-payment").default
const PaymentHistory =
  require("account/models/payment-history-collection").default

class ProfileStore extends require("lib/static-shim").default(
  require("lib/core/store/backbone-store").default
) {
  static initClass() {
    this.prototype.resources = {
      user: {
        model: User,
        requiredFields: ["first_name", "last_name", "email"]
      },
      payment: {
        model: UpdatePayment
      },
      payment_history: {
        collection: PaymentHistory,
        pluralize: false
      }
    }

    this.prototype.storeTriggerHandlers = {
      auth: {
        "user:signed:in"({ user, session }) {
          return this._mutate({ user })
        },
        "user:signed:out"() {
          return this.reset()
        }
      }
    }

    this.prototype.actions = {
      loadUserSubscription(forceReload) {
        if (forceReload == null) {
          forceReload = false
        }
        return this.getStore("subscribe").action.loadUserSubscription(
          forceReload
        )
      },

      loadPaymentHistory(forceReload) {
        if (forceReload == null) {
          forceReload = false
        }
        return this.resource.payment_history
          .list({}, forceReload)
          .then((result) => {
            const payment_history_download_url = result.download_url
            return this._mutate({ payment_history_download_url })
          })
      },

      selectProfileSection(section) {
        return this._mutate({ section })
      },

      saveUser(values) {
        if (values.hasOwnProperty("firstname")) {
          const { user } = this.getState()
          // submitting form data
          const update = {
            ...user,
            first_name: values.firstname,
            last_name: values.lastname,
            email: values.email,
            profile: {
              ...user.profile,
              bio: values.bio,
              location: values.location
            }
          }
          if (values.avatar.url) {
            update.profile.avatar = values.avatar.url
          }

          return this.resource.user.save(update)
        }

        return Promise.resolve()
      },

      updatePayment(token) {
        return this.resource.payment
          .save({ payment_token: token }, { method: "put" })
          .finally(() => {
            this.action.loadUserSubscription(true)
          })
      },

      pauseSubscription(pause_months) {
        return this.getStore("subscribe")
          .action.pauseSubscription(pause_months)
          .promise.then(() => {
            const pause_subscription_success =
              "Your subscription has been paused."
            return this._mutate({ pause_subscription_success })
          })
      },

      unPauseSubscription() {
        return this.getStore("subscribe")
          .action.unPauseSubscription()
          .promise.then(() => {
            const unpause_subscription_success =
              "Your subscription has been unpaused."
            return this._mutate({ unpause_subscription_success })
          })
      },

      cancelSubscription() {
        const subscribeStore = this.getStore("subscribe")
        let { subscription } = subscribeStore.getState()
        let msg = subscription.is_paused
          ? "Your subscription will not resume. "
          : "You cancelled your subscription. "

        return subscribeStore.action.cancelSubscription().promise.then(() => {
          ;({ subscription } = subscribeStore.getState())
          if (subscription.subscription != null) {
            msg += `\
You'll keep access to ${App.school.get("name")}
until ${moment(subscription.subscription.cycle_end_dtime).format(
              "MMMM Do YYYY"
            )}
and no additional charges will be made to your card.\
`
          }
          const cancel_subscription_success = msg
          return this._mutate({ cancel_subscription_success: msg })
        })
      },

      clearResponses() {
        return this._mutate({
          user_error: null,
          user_success: null,
          cancel_subscription_success: null,
          pause_subscription_success: null,
          unpause_subscription_success: null,
          update_payment_error: null
        })
      }
    }
  }

  defaults() {
    return {
      user: null, // user model
      payment_history: null,
      payment_history_download_url: null,
      section: null, // Which tab is the user looking at?

      payment_history_loading: false,

      password_error: null,
      cancel_subscription_success: null,
      pause_subscription_success: null,
      update_payment_success: null,
      update_payment_error: null
    }
  }

  computeURLForState() {
    const section = this.get("section")
    const url = `/home/${section}/`
    return url
  }

  bootstrapStore() {
    if (!window.bootstrappedData) {
      return
    }
    let user = bootstrappedData.user || null
    user = new User(user)
    return this._mutate({ user })
  }
}
ProfileStore.initClass()

export default window.App.stores.registerStore("profile", ProfileStore)
