import defaults from "lodash/defaults"
import path from "path-browserify"
import url from "url"
import { COMPLETION_FILTERS } from "../inbox/context/completion-filters"
import { getEnv, getIsLocalHost } from "../lib/env"

// TODO: remove need for global school!
declare global {
  interface Window {
    school: {
      id: number
      platform_version: number
      url: string
    }
  }
}

// platform routes

// NOTE: running into Babel transpile bug with named capture groups:
// SyntaxError: Expected atom at position
// export const COHORT_SECTION_RE = /\/(?<library>library)\/(?<resourceSlug>[a-zA-Z0-9_-]+)\/(?<cohortId>\d+)\/(?<section>[a-z]+)\//
// export const STEP_RE = /\/(?<library>library)\/(?<resourceSlug>[a-zA-Z0-9_-]+)\/(?<cohortId>\d+)\/(?<section>[a-z]+)\/(?<step>step)\/(?<stepSourceId>\d+)\//

const intRe = /^\d+$/

// NOTE: using RegExp constructor to avoid Babel transpile bug due to named groups.
export const RESOURCE_SLUG_ID = new RegExp("-(?<resourceId>\\d+(?=$))")

export const COHORT_SECTION_RE = new RegExp(
  "\\/(?<library>library)\\/(?<resourceSlug>[a-zA-Z0-9_-]*-(?<resourceId>\\d+))\\/(?<cohortId>(\\d+|_))\\/(?<section>[a-z]+)\\/"
)
export const STEP_RE = new RegExp(
  "\\/(?<library>library)\\/(?<resourceSlug>[a-zA-Z0-9_-]*-(?<resourceId>\\d+))\\/(?<cohortId>(\\d+|_))\\/(?<section>[a-z]+)\\/(?<step>step)\\/(?<stepSourceId>\\d+)\\/"
)
export const COMMUNITY_AREA_RE = new RegExp(
  "\\/(?<library>library)\\/(?<resourceSlug>[a-zA-Z0-9_-]*-(?<resourceId>\\d+))\\/(?<cohortId>(\\d+|_))\\/(?<section>[a-z]+)\\/(?<communityArea>[a-z]+)\\/"
)

export const getUrlValues = (
  re: RegExp,
  pathname?: string
): Record<string, string | number> => {
  pathname = pathname || window.location.pathname
  const match = pathname.match(re)
  const values = match ? match.groups! : {}
  // Process the values, expecting either string or number.
  const processedValues = Object.entries(values).reduce(
    (values, [key, value]) => ({
      ...values,
      // Parse the int if value matches int regex, otherwise use default value.
      [key]: intRe.test(value) ? parseInt(value) : value
    }),
    {}
  )
  return processedValues
}

// NOTE: expects only the resource slug, not a full pathname.
export const getResourceSlugId = (slug: string) => {
  const { resourceId } = getUrlValues(RESOURCE_SLUG_ID, slug)
  return resourceId
}

type CohortSection = "about" | "path" | "community"

type CohortSectionURLValues = {
  library: "library"
  resourceSlug: string
  resourceId: number
  cohortId: number
  groupId: number
  section: CohortSection
}

export const getCohortSectionUrlValues = (
  pathname?: string
): CohortSectionURLValues => {
  const values = getUrlValues(
    COHORT_SECTION_RE,
    pathname
  ) as CohortSectionURLValues
  return {
    ...values,
    // support groupId =(
    groupId: values.cohortId as number
  }
}

export const getCommunityAreaUrlValues = (pathname: string) =>
  getUrlValues(COMMUNITY_AREA_RE, pathname)

export const getCohortSectionBaseUrl = (options = {}) => {
  const { library, resourceSlug, cohortId, section } = defaults(
    options,
    getCohortSectionUrlValues()
  )
  const baseUrl = `/${library}/${resourceSlug}/${cohortId}/${section}/`
  return baseUrl
}

export const getCohortSectionUrl = (fragment: string) => {
  const baseUrl = getCohortSectionBaseUrl()
  return constructUrl(baseUrl, fragment)
}

export const getStoreUrl = () => {
  if (window.school && window.school.platform_version >= 2.8) {
    return `/store/`
  }
  return `/library/`
}

export const getStoreResourceUrl = (resourceSlug: string) => {
  if (window.school && window.school.platform_version >= 2.8) {
    return `/store/${resourceSlug}/`
  }
  return `/library/${resourceSlug}/about/`
}

// can also be used to get curriculum (base group id) path
export const getGroupUrl = (
  resourceSlug: string,
  groupId: string | number,
  section: CohortSection = "path"
) => {
  return `/library/${resourceSlug}/${groupId}/${section}/`
}

export const getCommunityAreaUrl = (
  resourceSlug: string,
  groupId: string | number,
  communityArea: string
) => {
  return constructUrl(
    getGroupUrl(resourceSlug, groupId, "community"),
    communityArea
  )
}

export const getDiscussionUrl = ({
  resourceSlug,
  groupId,
  stepId,
  discussionId
}: {
  resourceSlug: string
  groupId: string | number
  stepId: string | number
  discussionId: string | number
}) => {
  if (stepId) {
    return `/library/${resourceSlug}/${groupId}/path/step/${stepId}/discussion/${discussionId}/`
  }

  return `/library/${resourceSlug}/${groupId}/community/discussion/${discussionId}/`
}

export const getResponseUrl = ({
  resourceSlug,
  groupId,
  stepId,
  discussionId,
  responseId
}: {
  resourceSlug: string
  groupId: string | number
  stepId: string | number
  discussionId: string | number
  responseId: string | number
}) =>
  constructUrl(
    getDiscussionUrl({
      resourceSlug,
      groupId,
      stepId,
      discussionId
    }),
    `/response/${responseId}/`
  )

export const getGroupInviteUrl = (
  resourceSlug: string,
  groupId: string | number,
  section: string
) => {
  section = section || getCohortSectionUrlValues().section || "community"
  return `/library/${resourceSlug}/${groupId}/${section}/invite/`
}

export const getGroupMessageUrl = (
  resourceSlug: string,
  groupId: string | number,
  section: string
) => {
  section = section || getCohortSectionUrlValues().section || "community"
  return `/library/${resourceSlug}/${groupId}/${section}/message/`
}

export const getGroupMembersUrl = (
  resourceSlug: string,
  groupId: string | number,
  section: string
) => {
  section = section || getCohortSectionUrlValues().section || "community"
  return `/library/${resourceSlug}/${groupId}/${section}/member/`
}

export const getGroupCommunityUrl = ({
  resourceSlug,
  groupId,
  section
}: {
  resourceSlug: string
  groupId: string | number
  section: string
}) => {
  section = section || getCohortSectionUrlValues().section || "community"
  return `/library/${resourceSlug}/${groupId}/${section}/community/`
}

export const getGroupAnalyticsUrl = (
  resourceSlug: string,
  groupId: string | number,
  section: string
) => {
  section = section || getCohortSectionUrlValues().section || "path"
  return `/library/${resourceSlug}/${groupId}/${section}/analytics/`
}

export const getPathItemAnalyticsUrl = (
  resourceSlug: string,
  groupId: string | number,
  sourceId: string | number
) => {
  return `/library/${resourceSlug}/${groupId}/path/analytics/${sourceId}/`
}

export const getPathItemLessonScheduleUrl = (
  resourceSlug: string,
  groupId: string | number,
  sourceId: string | number
) => {
  return `/library/${resourceSlug}/${groupId}/path/schedule/${sourceId}/`
}

export const getPathItemScheduleUrl = (
  resourceSlug: string,
  groupId: string | number,
  sourceId: string | number
) => {
  return `/library/${resourceSlug}/${groupId}/path/schedule/${sourceId}/`
}

// TODO: step different from lesson path item url?
export const getPathItemNotesUrl = (
  resourceSlug: string,
  groupId: string | number,
  sourceId: string | number
) => {
  return `/library/${resourceSlug}/${groupId}/path/notes/${sourceId}/`
}

export const getPathItemSettingsUrl = (
  resourceSlug: string,
  groupId: string | number,
  stepId: string | number
) => {
  return `/library/${resourceSlug}/${groupId}/path/step/${stepId}/settings/`
}

export const getPathItemCompletionsUrl = (
  resourceSlug: string,
  groupId: string | number,
  pathId: string | number,
  sourceId: string | number,
  filter = "review"
) => {
  const params = new URLSearchParams({ step: sourceId + "", filter })
  return `/library/${resourceSlug}/${groupId}/path/${pathId}/completion/?${params}`
}

export const getStepUrl = (
  resourceSlug: string,
  groupId: string | number,
  stepId: string | number
) => {
  return `/library/${resourceSlug}/${groupId}/path/step/${stepId}/`
}

export const getLessonUrl = (
  resourceSlug: string,
  groupId: string | number,
  lessonId: string | number
) => {
  return `/library/${resourceSlug}/${groupId}/path/lesson/${lessonId}/`
}

export const getPathCompletionsUrl = () => {
  return `/completion/`
}

export const getManageResourceUrl = (resourceId: string | number) => {
  return `/manage/resource/${resourceId}/`
}

export const getManageLicenseOfferingUrl = (resourceId: string | number) => {
  return `/manage/resource/${resourceId}/cohorts/license/`
}

export const getManageGroupUrl = (
  resourceId: string | number,
  groupId: string | number
) => {
  return `/manage/group/${resourceId}/${groupId}/`
}

export const getManageGroupCommunityUrl = (
  resourceId: string | number,
  groupId: string | number
) => {
  return `/manage/group/${resourceId}/${groupId}/community/`
}

export const getManageNewGroupUrl = (resourceId: string | number) => {
  return `/manage/group/${resourceId}/new/`
}

export const getManageGroupsUrl = (resourceId: string | number) => {
  return `/manage/resource/${resourceId}/group/`
}

type InboxUrlOptions = {
  cohortId?: number | string
  groupId?: number | string
  stepId?: number | string
  userId?: number | string
  selected?: number | string
  selectedParent?: number | string
  panel?: number | string
  filter?: number | string
}

export const getInboxUrl = (options: InboxUrlOptions = {}) => {
  let url = "/inbox/"

  // NOTE: step_id forces Inbox to be opend in single item mode since
  // it scopes the data to that single item.
  const paramMap: Record<keyof InboxUrlOptions, string> = {
    cohortId: "cohort_id",
    groupId: "group_id",
    stepId: "step_id",
    userId: "user_id",
    selected: "ui_selected",
    selectedParent: "ui_selected_parent",
    panel: "ui_panel",
    filter: "filter"
  }

  // When linking directly to a step, always open the step in the content|panel view,
  // rather than showing it in the list view.
  if (options.stepId && !options.selected) {
    options.selected = options.stepId
  }

  options = defaults(options, {
    filter: COMPLETION_FILTERS.all.key
  })

  const paramData = Object.entries(paramMap).reduce(
    (acc, [optionKey, paramKey]) =>
      options[optionKey as keyof InboxUrlOptions]
        ? {
            ...acc,
            [paramKey]: options[optionKey as keyof InboxUrlOptions] + ""
          }
        : acc,
    {} as Record<keyof InboxUrlOptions, string>
  )

  const params = new URLSearchParams(paramData).toString()

  if (params) {
    url += `?${params}`
  }

  return url
}

// helpers

const ENSURE_INITIAL_FORWARDSLASH_RE = /(^\/|^)/
const INITIAL_FORWARDSLASH_RE = /^\//
const FINAL_FORWARDSLASH_RE = /(^[^?#](?!(.*\/$|.*[?#])).*)/

export const constructUrl = (...fragments: any[]) =>
  fragments
    // "filter" out falsey values and transform to string
    .map((fragment) => (fragment || "").toString())
    .reduce((base, fragment, i) => {
      if (i !== 0) {
        // remove initial "/" for joining base with fragment
        fragment = fragment.replace(INITIAL_FORWARDSLASH_RE, "")
      } else if (isRelativeUrl(fragment)) {
        // ensure initial "/"
        fragment = fragment.replace(ENSURE_INITIAL_FORWARDSLASH_RE, "/")
      }

      // append final "/" if initial is not "?" and initial isn't followed by a final "/" or any "?"
      fragment = fragment.replace(FINAL_FORWARDSLASH_RE, "$1/")

      return `${base}${fragment}`
    }, "")

export const getSchoolUrl = (...fragments: string[]) => {
  let url = `${window.location.protocol}//${window.location.hostname}`
  if (getIsLocalHost()) {
    url = window.school && window.school.url
  }

  return constructUrl(url, ...fragments)
}

export const isSchoolUrl = (fullUrl: string) => {
  try {
    const host = url.parse(fullUrl).hostname
    const schoolHost = url.parse(getSchoolUrl()).hostname
    return host === schoolHost
  } catch (error) {
    console.warn("Error parsing url:", error)
    return false
  }
}

export const getRelativeUrl = (absUrl: string) => {
  const route = getRelativeRoute(absUrl)
  return `${route.pathname}${route.search}${route.hash}`
}

export const getRelativeRoute = (url: string | URL) => {
  if (isRelativeUrl(url)) {
    // URL constructor expects base fragment to be the origin, fake it
    url = constructUrl("https://foo.com/", url)
  } else if (typeof url === "object") {
    url = constructUrl("https://foo.com/", url.pathname, url.search)
  }

  // Get url parts to form route.
  const { pathname, search, hash } = new URL(url)
  return { pathname, search, hash }
}

export const isRelativeUrl = (url: any) =>
  Boolean(typeof url === "string" && url.indexOf("http") !== 0)

export const getRoute = (url: string, fragment: string, search?: string) => {
  const route = getRelativeRoute(isRelativeUrl(url) ? getSchoolUrl(url) : url)
  const pathname = constructUrl(route.pathname, fragment)
  const queryObject: Record<string, string | null> = {
    ...getQueryObject(route.search),
    ...getQueryObject(search)
  }
  const filteredQueryObject = Object.keys(queryObject).reduce<
    Record<string, string>
  >((obj, key) => {
    // only include non-null search params. This enables clearing a search param by setting it to null
    const value = queryObject[key]
    if (value && decodeURI(value) != null) {
      obj[key] = value
    }
    return obj
  }, {})
  const newSearch = new URLSearchParams(filteredQueryObject)
  const searchString = decodeURIComponent(newSearch.toString())

  return {
    ...route,
    pathname,
    search: searchString ? `?${searchString}` : ""
  }
}

export const getQueryObject = (search?: string) => {
  if (search) {
    const searchParams = new URLSearchParams(search)
    return Array.from(searchParams.keys()).reduce<
      Record<string, string | null>
    >((m, n) => {
      m[n] = searchParams.get(n)
      return m
    }, {})
  }
  return {}
}

// localize the given url - convenient for local testing
export const getLocalizedUrl = (url: string) => {
  if (isRelativeUrl(url)) return url
  if (!getIsLocalHost()) return url

  const relativeUrl = getRelativeUrl(url)
  const { origin } = window.location
  return constructUrl(origin, relativeUrl)
}

// Returns an object since you may want to have access to the matched key
// in cases where a regex patter is provided.
export const getParam = (search: string, pattern: RegExp | string) => {
  // Allow for location to be a url string
  const params = new URLSearchParams(search)

  // Just returning the first key that matches the regular expression.
  // Could return a list of all keys that match, maybe later.
  if (pattern instanceof RegExp) {
    for (const [key, value] of Array.from(params.entries())) {
      const match = key.match(pattern)
      if (match) {
        // Optionally set first matched group as key.
        if (match.groups) return { [Object.values(match.groups)[0]]: value }
        return { [key]: value }
      }
    }
  }

  if (typeof pattern === "string") {
    return { [pattern]: params.get(pattern) }
  }

  // Let caller handle params directly.
  if (!pattern) return getQueryObject(search)

  // Otherwise empty object as result.
  return {}
}

export const getPlatformUrl = (pathname: string) => {
  const baseUrl = getEnv({
    production: "https://paths.pathwright.com",
    staging: "https://paths.pathwrightstaging.com",
    development: "http://paths.pathwrightdev.com",
    testing: "https://paths.pantswright.com"
  })
  return constructUrl(baseUrl, pathname)
}

export const AUGMENTATION_PANEL_QUERY_PARAM = "panel"

// Get the card path from the ?AUGMENTATION_PANEL_QUERY_PARAM query param, if it exists.
export function getCardPath(location: Location) {
  return new URLSearchParams(location.search).get(
    AUGMENTATION_PANEL_QUERY_PARAM
  )
}

// Accepts a location and a pathname. Returns the proper
// card route based on whether a ?AUGMENTATION_PANEL_QUERY_PARAM query param exists
// in the provided location.
export function getCardTo(location: Location, cardPathSegment: string) {
  const originalCardPathSegment = cardPathSegment

  // Find the index at which the cardPathSegment ceases to match the start
  // of the targetStr. We want to strip out this portion of the cardPathSegment.
  function getCardPathSegmentIndex(cardPathSegment: string, targetStr: string) {
    let index = 0
    let targetSubStr = ""

    while (targetSubStr.length === index) {
      targetSubStr = targetStr.slice(0, index + 1)

      if (cardPathSegment.startsWith(targetSubStr)) {
        index++
      }
    }

    // Strip back to the last occurance of "/" slash so as not to include any partial segments.
    index = targetSubStr.lastIndexOf("/")

    return Math.max(0, index)
  }

  // It's possible for the location.pathname to partially match the provided cardPathSegment.
  // This is when a to prop combines the current location.pathname with a path segment,
  // like path.join(window.location.pathname, "/example/").
  // In this case, we need to strip out the window.location.pathname from the cardPathSegment
  const pathnameIndex = getCardPathSegmentIndex(
    cardPathSegment,
    location.pathname
  )
  cardPathSegment = cardPathSegment.slice(pathnameIndex)

  const cardPath = getCardPath(location)
  if (cardPath) {
    if (
      // Only proceeding if we've found a cardPathSegment matching more than just the opening forward slash.
      cardPathSegment !== "/" &&
      // Only proceeding if either the cardPathSegment doesn't match the beginning of the locaiton.pathname
      // or it matches the entirety of the location.pathname.
      (!pathnameIndex || pathnameIndex === location.pathname.length - 1)
    ) {
      // It's possible for the cardPath to paritally match the provided cardPathSegment.
      // This is when a to prop combines some of the current cardPath with a path segment,
      // like when at ?AUGMENTATION_PANEL_QUERY_PARAM=/example/a/ and a cardPathSegment is for /example/b/.
      // In this case, we need to use the cardPathSegment as the new ?AUGMENTATION_PANEL_QUERY_PARAM path.
      const cardPathIndex = getCardPathSegmentIndex(cardPathSegment, cardPath)

      // Now derive the next card path by either appending or flat out using the cardPathSegment.
      const nextCardPath =
        // Always append when the provided cardPathSegment has matched part of the beginninf of the
        // location.pathname, which indicates that the cardPathSegment is meant to be appended to
        // the existing url, not to replace it.
        cardPathIndex && !pathnameIndex
          ? cardPathSegment
          : path.join(cardPath, cardPathSegment)

      // Update search params. Replacing ?AUGMENTATION_PANEL_QUERY_PARAM path with next card path.
      const searchParams = new URLSearchParams(location.search)
      searchParams.set(AUGMENTATION_PANEL_QUERY_PARAM, nextCardPath)
      const searchString = decodeURIComponent(searchParams.toString())

      // Then update location url with card path, retaining other query params, if any.
      return `${location.pathname}?${searchString}`
    } else {
      // Get the pathname segment, slicing from beginning of current pathname to the end
      // of the matched segment, or to the end of the str when no matched segment found.
      const pathnameSegment = location.pathname.slice(
        0,
        pathnameIndex || location.pathname.length
      )

      // Remove the panel query param.
      const search = new URLSearchParams(location.search)
      search.delete(AUGMENTATION_PANEL_QUERY_PARAM)
      // Preserve "legacy" card routing.
      return `${path.join(pathnameSegment, cardPathSegment)}${search}`
    }
  } else {
    // When no card path exists, just return the provided original.
    return originalCardPathSegment
  }
}
