import { Spinner } from "@chakra-ui/react"
import React, { Suspense } from "react"

const AdaptedSignedInContainer = React.lazy(
  () =>
    import(
      "./AdaptedSignedInContainer" /* webpackChunkName: "AdaptedSignedInContainer" */
    )
)

function LazyAdaptedSignedInContainer(props: any) {
  return (
    <Suspense fallback={<Spinner />}>
      <AdaptedSignedInContainer {...props} />
    </Suspense>
  )
}

export default LazyAdaptedSignedInContainer
