const Session = require("./models/session").default
const User = require("./models/user").default

const { getStoreUrl } = require("store/utils")
const { clearAllResourceCaches } = require("lib/core/api/resource-cache")
import {
  getRelativeRoute,
  getRelativeUrl
} from "@pathwright/web/src/modules/utils/urls"
import get from "lodash/get"

const getAuthData = () => {
  window.bootstrappedData.session = window.bootstrappedData.session || {}
  window.bootstrappedData.user = window.bootstrappedData.user || null

  let { session, user } = window.bootstrappedData
  user = new User(user)
  session.user = user
  session = new Session(session)
  const is_authenticated = session.get("is_authenticated")

  return {
    user,
    session,
    is_authenticated
  }
}

class AuthStore extends require("lib/static-shim").default(
  require("lib/core/store/backbone-store").default
) {
  static initClass() {
    this.prototype.storeTriggers = {
      "user:signed:in"() {
        return { user: this.get("user"), session: this.get("session") }
      },
      "user:signed:out"() {
        return null
      },
      "user:auth:changed"() {
        return this.request.isAuthenticated()
      }
    }

    this.prototype.storeTriggerHandlers = {
      school: {
        "bootstrapped:school"() {
          this._mutate(getAuthData())
        }
      },
      profile: {
        "user:updated"(user) {
          if (user.id === this.get("user")?.id) {
            const session = this.get("session")
            session.set({ user })
            return this._mutate({ session, user })
          }
        }
      }
    }

    this.prototype.actions = {
      reAuth() {
        if (
          !this.get("is_redirecting_to_sign_in") &&
          !this.get("is_deauthenticating")
        ) {
          this._mutate({ is_deauthenticating: true })
          this.action.setNextUrl()
          const next_url = this.get("next_url")
          return this.action
            .signOut()
            .promise.then(() => this.reset())
            .then(() => this.action.redirectToSignIn(next_url))
            .then(() => this._mutate({ is_deauthenticating: false }))
            .then(() => this._mutate({ is_redirecting_to_sign_in: false }))
        }
      },

      auth(email, password, membershipInviteToken) {
        const session = this.get("session")
        session.set({ username: email, password })
        return this.action.signIn("password", membershipInviteToken).promise
      },

      signIn(authMethod, membershipInviteToken) {
        if (authMethod == null) {
          authMethod = "password"
        }
        if (membershipInviteToken == null) {
          membershipInviteToken = null
        }
        return new Promise(
          function (resolve, reject) {
            const session = this.get("session")
            session.set({ auth_method: authMethod })
            this._mutate({ is_authenticating: true })
            return session
              .save({ invitation_code: membershipInviteToken })
              .then(() => {
                this.action.setDataAfterAuth(() => {
                  resolve(session)
                })
              })
              .fail((err) => {
                this._mutate({ authentication_error: err })
                return reject(err)
              })
          }.bind(this)
        )
      },

      signOut() {
        return new Promise(
          function (resolve, reject) {
            if (!this.get("session")) {
              return resolve()
            }
            clearAllResourceCaches()
            const logout_url = get(
              window,
              "bootstrappedData.integrations.sso.current_provider.logout_url"
            )
            // If the user is signed in via SSO, sign them out using the current provider logout url
            // otherwise, sign them out as usual
            if (logout_url) {
              return (window.location = logout_url)
            } else {
              return this.get("session")
                .destroy()
                .then(() => {
                  App.getStore("school").action.bootstrapSchool((data) => {
                    this._mutate({
                      user: null,
                      is_authenticated: false
                    })
                    this.storeTrigger("user:signed:out")
                    this.storeTrigger("user:auth:changed")
                    resolve()
                  })
                })
            }
          }.bind(this)
        )
      },

      reloadUser() {
        const options = { id: this.get("user")?.id }
        const user = new User(options)
        return user.fetch().done((result) => {
          const session = this.get("session")
          session.set({ user })
          return this._mutate({ session, user })
        })
      },

      setDataAfterAuth(callback) {
        const session = this.get("session")
        let user = session.get("user") || {}
        const updates = { is_authenticating: false }

        if (!_.isFunction(user.toJSON)) {
          user = new User(user)
          session.set({ user }, { silent: true })
        }

        if (this.get("user")?.id !== user.id) {
          updates.user = user
        }

        if (this.get("session")?.get("is_authenticated")) {
          updates.is_authenticated = true
        }

        // HACK: delaying setting auth data until bootstrappedData is loaded
        App.getStore("school").action.bootstrapSchool((data) => {
          clearAllResourceCaches()
          this._mutate(updates)
          this._setLegacyVars()
          this.storeTrigger("user:signed:in")
          this.storeTrigger("user:auth:changed")
          if (callback) {
            callback()
          }
        })
      },

      setNextUrl(nextUrl) {
        this._mutate({ next_url: this.request.getNextUrl(nextUrl) })
      },

      clearNextUrl() {
        if (window.location.pathname !== "/auth/sign-in/") {
          const { next_url } = this.getState()
          if (next_url) {
            this._mutate({ next_url: null })
          }
        }
      },

      redirectToSignIn(next_url) {
        const nextUrl = this.request.getNextUrl(next_url)
        // TODO: don't hard code this
        this._mutate({ is_redirecting_to_sign_in: true })

        window.App.navigate({
          pathname: "/auth/sign-in/",
          query: nextUrl ? { next: nextUrl.pathname } : {}
        })
      },

      reloadBrowserWindow(url) {
        if (url == null) {
          url = null
        }
        if (url) {
          return (window.location.href = url)
        } else {
          return window.location.reload()
        }
      },

      checkSession() {
        const session = this.get("session") || new Session()
        this._mutate({ is_checking_session: true, has_checked_session: true })
        return new Promise((resolve, reject) => {
          session.check().always(() => {
            this._mutate({ is_checking_session: false })
            this.action.setDataAfterAuth(resolve)
          })
        })
      },

      setNoReloadOnAuth(no_reload_on_auth) {
        return this._mutate({ no_reload_on_auth })
      },

      setAuthState(auth_state) {
        return this._mutate({ auth_state })
      },

      showSignIn() {
        return window.App.execute("show:sign-in:flow")
      },

      clearErrors() {
        return this._mutate({
          authentication_error: null,
          signup_error: null,
          password_reset_error: null
        })
      }
    }

    this.prototype.requests = {
      isAuthenticated() {
        return this.get("is_authenticated")
      },

      canManage() {
        const can_manage =
          this.get("user")?.get("is_superuser") ||
          this.get("user")?.get("can_manage_school")
        if (this.request.isAuthenticated() && can_manage) {
          return true
        } else {
          return false
        }
      },

      getNextUrl(nextUrl) {
        const defaultNextUrl = `${window.location.pathname}${window.location.search}`
        // Construct a route that can more easily be manipulated.
        const nextRoute = getRelativeRoute(nextUrl || defaultNextUrl)

        switch (nextRoute.pathname) {
          // Ignore certain routes:
          case "/auth/sign-out/":
          case "/inactive/":
            return null
          default:
            return getRelativeUrl(nextRoute)
        }
      }
    }
  }

  defaults() {
    return {
      user: null, // the current signed in user
      session: null, // the current session
      next_url: null, // the route to navigate to after auth

      // UI
      auth_state: null, // splash, sign-in, sign-up
      is_checking_session: false,
      is_authenticating: false,
      is_deauthenticating: false,
      is_authenticated: false,
      authentication_error: null,
      is_redirecting_to_sign_in: false,
      has_checked_session: false,
      is_signing_up: false,
      signup_error: null,
      no_reload_on_auth: false
    }
  }

  initializeStore() {
    return this._listenForUnauthorized()
  }

  reset(options) {
    super.reset(options)
    const user = new User(null)
    const session = new Session({ user })
    return this._mutate({ user, session })
  }

  initShouldReloadWatcher() {
    const session = this.get("session")

    return this.listenTo(session, "change:is_authenticated", function () {
      const navStore = this.getStore("navigation")

      if (!session.get("is_authenticated")) {
        if (navStore.request.matches("home/")) {
          return window.App.navigate(getStoreUrl(), { trigger: true })
        }
      }

      if (this.get("no_reload_on_auth") === true) {
        return
      }

      if (navStore.get("url") === "") {
        console.log(
          "reloading page bacause we're on an old view and auth state changed",
          navStore.get("url")
        )
        return this.action.reloadBrowserWindow()
      }
    })
  }

  computeURLForState() {
    let url = "/auth/"
    if (this.get("auth_state")) {
      url = `${url}${this.get("auth_state")}/`
    }
    return url
  }

  _setLegacyVars() {
    // Hack for legacy support
    const user = this.get("user")
    const session = this.get("session")

    // Legacy code expects user.membership
    if (!user.get("membership")) {
      if (session.get("school_membership")) {
        user.set("membership", session.get("school_membership"), {
          silent: true
        })
      }
    }

    window.App.user = user
    window.App.session = session
  }

  _shouldReAuthAfter401(xhr) {
    const responseText =
      (xhr.responseText != null ? xhr.responseText.toLowerCase() : undefined) ||
      ""
    if (responseText.indexOf("invalid token") > -1) {
      // If the client has an invalid token, we need to sign-out/re-login
      return true
    }
    // probably just a permission denied thing
    return false
  }

  _shouldCheckSessionAfter401(xhr) {
    const isSessionCheck =
      (xhr.url != null ? xhr.url.indexOf("/session") : undefined) > -1
    if (isSessionCheck) {
      return false
    }
    if (this.get("has_checked_session")) {
      return false
    }
    return true
  }

  _listenForUnauthorized() {
    // Listens for 401 errors from ajax requests and attempts
    // to resolve an out of sync session
    return $(document).ajaxError((event, xhr) => {
      if (xhr.status === 400) {
        const responseText =
          (xhr.responseText != null
            ? xhr.responseText.toLowerCase()
            : undefined) || ""
        if (responseText.indexOf("already logged in") > -1) {
          this.action.checkSession()
        }
      }
      if (xhr.status === 401) {
        if (this._shouldReAuthAfter401(xhr)) {
          return this.action.reAuth()
        } else if (this._shouldCheckSessionAfter401(xhr)) {
          return this.action.checkSession()
        }
      }
    })
  }

  bootstrapStore() {
    this._mutate(getAuthData())
    this._setLegacyVars()
    return this.initShouldReloadWatcher()
  }
}
AuthStore.initClass()

export default window.App.stores.registerStore("auth", AuthStore)
