// TODO: make sure this doesn't add too much to the bundle size or we'll need to import dynimacally
import "highlight.js/styles/github.css"

let DiscussionDetailView
require("./styles/discussion-detail.css")

const ResponseListView = require("./response-list").default
const LoadingView = require("lib/loading/views/loading-view").default
const DiscussionResponseForm = require("./discussion-response-form").default
const DiscussionDetailTemplate = require("./templates/discussion-detail.html")

export default DiscussionDetailView = (function () {
  DiscussionDetailView = class DiscussionDetailView extends (
    require("lib/static-shim").default(require("lib/core/layout").default)
  ) {
    static initClass() {
      this.prototype.className = "discussion-detail-view"

      this.prototype.regions = {
        responses: ".discussion-responses-region",
        response: ".discussion-response-region"
      }

      this.prototype.behaviors = {
        ConnectStore: {
          store: "discussion",
          events: {
            "change:response_id"() {
              const { discussion_id, response_id } =
                this.discussionStore.getState()
              const prev_response_id =
                this.discussionStore.previous("response_id")
              const prev_discussion_id =
                this.discussionStore.previous("discussion_id")

              if (
                discussion_id &&
                // Require that we are only changing the response, apart from the discussion.
                // (Don't want to load responses in this case when the discussion has changed.)
                (!prev_discussion_id || discussion_id === prev_discussion_id) &&
                response_id !== prev_response_id
              ) {
                return this.loadResponses()
              }
            },
            "change:new_responses_available"() {
              return this.$(".new-responses-prompt").toggle(
                this.discussionStore.get("new_responses_available")
              )
            }
          }
        },
        MathJax: {},
        State: {
          getStateKey() {
            return `discussion-view-${this.model.id}`
          },
          viewProperty: "vstate"
        },
        ViewStates: {
          force_state_change: true,
          states: {
            view() {
              this.view.options.edit = false
              this.renderView()
              this.triggerMethod("show")
              return this.view.loadResponses()
            }
          }
        }
      }

      this.prototype.ui = {
        loadNewResponses: "#load-new-responses"
      }

      this.prototype.events = {
        "click @ui.discussionContextLink": "onSelectContextLink",
        "click @ui.loadNewResponses": "reloadResponses"
      }

      this.prototype.triggers = { "click @ui.subscribe": "toggle:subscribe" }
    }

    initialize() {
      this.listenTo(App.vent, "discussion:show", (id) => {
        return this.$el.scrollTop(0)
      })
      return (window.detail = this)
    }

    getTemplate() {
      return DiscussionDetailTemplate
    }

    onRender() {
      if (this.model.get("user_permissions").can_respond) {
        // Show response form
        const responseForm = new DiscussionResponseForm({
          discussion: this.model
        })
        this.listenTo(responseForm, "model:save", this._onResponseAdded)
        // Defer for binding purposes. If not defered, the responseForm's
        // binding is overridden by this view's binding.
        // TODO: could this be fixed in the bind behavior?
        _.defer(() => {
          if (
            window.App.session.get("is_authenticated") &&
            this.model.get("discussion_type") < 15
          ) {
            return this.response.show(responseForm)
          }
        })
      }
    }

    getResponseOptions() {
      const options = {
        context: this.model.get("context_str"),
        response_id: this.discussionStore.get("response_id")
      }
      if (options.filters == null) {
        options.filters = {
          ordering: this.discussionStore.get("response_default_ordering")
        }
      }
      return options
    }

    loadResponses(resourceLoaderOptions) {
      // TEMPORARY: f/r mvp don't show responses
      if (this.model.get("discussion_type") >= 15) {
        return
      }

      // Show response list
      this.responses.show(
        new LoadingView({
          spinner_type: "small",
          spinner_options: {
            color: "#999999"
          }
        })
      )

      this.discussionStore.action
        .loadResponses(this.getResponseOptions(), resourceLoaderOptions)
        .promise.then(this.showResponseList.bind(this))
    }
    //App.request("get:responses", @model.id, options).then(@showResponseList.bind(@))

    reloadResponses() {
      return this.discussionStore.action
        .reloadResponses(this.getResponseOptions())
        .promise.then(this.showResponseList.bind(this))
    }

    showResponseList(responseCollection) {
      this.responseCollection = responseCollection
      this.responseCollection.discussionID = this.model.id
      this.responseList = new ResponseListView({
        model: this.model,
        collection: this.responseCollection,
        onResponseAdded: (response) =>
          this.options.onUpdateDiscussion &&
          this.options.onUpdateDiscussion(this.model),
        onResponseArchived: (response) =>
          this.options.onUpdateDiscussion &&
          this.options.onUpdateDiscussion(this.model)
      })
      return this.responses.show(this.responseList)
    }

    _onResponseAdded(model) {
      this.options.onUpdateDiscussion &&
        this.options.onUpdateDiscussion(this.model)

      return this.responseCollection.add(model, { at: 0 })
    }
  }
  DiscussionDetailView.initClass()
  return DiscussionDetailView
})()
