import { useApolloClient, useMutation } from "@apollo/client"
import { Box, IconButton, VStack } from "@chakra-ui/react"
import Alert from "@pathwright/ui/src/components/alert/Alert"
import BlankSlate from "@pathwright/ui/src/components/blank/BlankSlate"
import Button from "@pathwright/ui/src/components/button/Button"
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
import Card from "@pathwright/ui/src/components/card/Card"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import CardHeader from "@pathwright/ui/src/components/card/CardHeader"
import ErrorMessage from "@pathwright/ui/src/components/error/ErrorMessage"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import LoadingCircle from "@pathwright/ui/src/components/loading/LoadingCircle"
import Text from "@pathwright/ui/src/components/ui/Text"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import get from "lodash/get"
import { useState } from "react"
import styled from "styled-components"
import {
  OrderByDir,
  PositionedSchoolSubscriptionPlanFragmentDoc,
  PositioningContextTypeInput,
  useSchoolSubscriptionPlansQuery
} from "../api/generated"
import LazyAdaptedAuthContainer from "../auth/LazyAdaptedAuthContainer"
import ChakraTooltip from "../components/ChakraTooltip"
import { useCurrencyFormatter } from "../currency/CurrencyFormatterProvider"
import Pathicon from "../pathicon/Pathicon"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import {
  PositioningContextProvider,
  sortPositionedObjects,
  usePositioningContext
} from "../positioning/PositioningContext"
import StripeProductCard from "../stripe/StripeProductCard"
import useStripeProducts from "../stripe/graphql/useStripeProducts"
import REDEEM_SCHOOL_GIFT_SUBSCRIPTION from "../subscription/graphql/redeem-school-gift-subscription-mutation"
import SCHOOL_SUBSCRIPTION_DISCOUNT_QUERY from "../subscription/graphql/school-subscription-discount-query"
import {
  getIsSubscriptionActive,
  getIsSubscriptionDelinquent
} from "../subscription/utils"
import { useUserCommunityContext } from "../user/UserCommunityContext"
import USER_SCHOOL_SUBSCRIPTION_QUERY from "../user/graphql/user-school-subscription-query"
import { getRelativeUrl, getSchoolUrl } from "../utils/urls"
import OrderDiscountForm from "./OrderDiscountForm"
import OrderStripeCheckoutForm from "./OrderStripeCheckoutForm"
import { getIntervalDisplayName } from "./utils/subscription"

const StyledCardBlock = styled(CardBlock)`
  background-color: rgb(245, 245, 245);

  .SubscriptionOptions > div {
    background-color: white;
  }
`

export default function OrderSubscriptionCard({
  card,
  planIds,
  visibilityCode,
  visibilityCodeFilter,
  redirectTo,
  inviteToken,
  userPropertyDefaults
}) {
  const { school } = usePathwrightContext()
  const { tc, tca } = useTranslate()
  const { activeCommunityGroupSubscription } = useUserCommunityContext()
  const userSubscriptionQuery = useQuery(USER_SCHOOL_SUBSCRIPTION_QUERY)
  const userSubscription = get(userSubscriptionQuery, "data.me.school_subscription.subscription") // prettier-ignore
  const hasActiveSubscription =
    getIsSubscriptionActive(userSubscription) ||
    !!activeCommunityGroupSubscription

  return (
    <NarrowCard card={card} navless noaction>
      <CardHeader
        card={card}
        title={
          hasActiveSubscription ? tca("Your Subscription") : tc("Subscribe")
        }
        meta={tc(`To {{ schoolName }}`, { schoolName: school.name })}
      />
      <OrderSubscriptionFlow
        planIds={planIds}
        visibilityCode={visibilityCode}
        visibilityCodeFilter={visibilityCodeFilter}
        redirectTo={redirectTo}
        inviteToken={inviteToken}
        userPropertyDefaults={userPropertyDefaults}
      />
    </NarrowCard>
  )
}

OrderSubscriptionCard.displayName = "OrderSubscriptionCard"

export function OrderSubscriptionFlow({
  planIds,
  productIds,
  visibilityCode,
  visibilityCodeFilter,
  redirectTo,
  inviteToken,
  userPropertyDefaults
}) {
  const { me, school } = usePathwrightContext()
  const {
    communityGroupMemberships,
    isCommunityGroupAdmin,
    activeCommunityGroupSubscription,
    communityGroupWithActiveSubscription,
    hasActiveMembership
  } = useUserCommunityContext()

  // To be safe, let's default the redirectTo to the current location if none provided.
  redirectTo ||= window.location.pathname + window.location.search

  const { t, tc, tca, Trans } = useTranslate()

  // Selection controls step of subscribe flow
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState(null)
  const [authStep, setAuthStep] = useState(false)
  const [stripeProducts, stripeProductsQuery] = useStripeProducts({
    ids: productIds
      ? {
          in: productIds
        }
      : null
  })

  const userSubscriptionQuery = useQuery(USER_SCHOOL_SUBSCRIPTION_QUERY)
  const userSubscription = get(userSubscriptionQuery, "data.me.school_subscription.subscription") // prettier-ignore

  const positioningContext = {
    contextType: PositioningContextTypeInput.SubscribeFlow,
    // TODO: what context key should be used?
    contextKey: "subscribe:flow"
  }
  const schoolSubscriptionPlansQuery = useSchoolSubscriptionPlansQuery({
    variables: {
      ids: planIds
        ? {
            in: planIds
          }
        : null,
      visibility_code: visibilityCode || null,
      visibility_code_filter: visibilityCodeFilter || null,
      positioningContext,
      orderBy: [{ position: OrderByDir.Asc }, { id: OrderByDir.Asc }]
    }
  })
  const flattenedSchoolSubscriptionPlans = schoolSubscriptionPlansQuery.data
    ? schoolSubscriptionPlansQuery.data.school.subscription_plans.edges.map(
        (edge) => edge.node
      )
    : []

  const schoolSubscriptionPlans = sortPositionedObjects(
    flattenedSchoolSubscriptionPlans
  )

  const error =
    userSubscriptionQuery.error ||
    stripeProductsQuery.error ||
    schoolSubscriptionPlansQuery.error
  const loading =
    userSubscriptionQuery.loading ||
    stripeProductsQuery.loading ||
    schoolSubscriptionPlansQuery.loading

  const planVisibilityCode =
    visibilityCode || (visibilityCodeFilter && visibilityCodeFilter.eq)

  if (error) {
    return (
      <BlankSlate
        icon="statuscaution"
        heading={tc("discussion.responses.unexpected_error")}
        body={<ErrorMessage error={error} />}
      />
    )
  }

  if (loading) {
    return (
      <VStack minH="300px">
        <LoadingCircle />
      </VStack>
    )
  }

  if (
    getIsSubscriptionActive(userSubscription) &&
    !userSubscription.trial_end_dtime
  ) {
    return getIsSubscriptionDelinquent(userSubscription) ? (
      <BlankSlate
        icon="subscription"
        heading="Your subscription is expired."
        body="When attempting to renew your subscription the payment method failed. After multiple attempts, your subscription was cancelled. To restore your subscription access, please update your payment method:"
        primaryAction={{
          label: "Update Payment Method",
          to: "/manage/profile/billing/payment/"
        }}
      />
    ) : (
      <BlankSlate
        icon="subscription"
        heading={`${tc(`You’ve already subscribed to {{ school }}`, {
          school: school.name
        })}!`}
        body={`${tc(`You may manage your subscription under your profile`)}.`}
      />
    )
  }

  if (communityGroupMemberships.length) {
    if (isCommunityGroupAdmin) {
      if (communityGroupWithActiveSubscription) {
        const group = communityGroupWithActiveSubscription
        if (group.seat_limit > 1) {
          return (
            <StyledBlankSlate
              stack
              icon="subscription"
              heading={
                <>
                  <Trans
                    i18nKey="Your group subscription to<br />{{ school }} is active"
                    tOptions={{
                      school: school.name
                    }}
                  />
                </>
              }
              primaryAction={{
                to: `/community/${group.id}/`,
                children: tc("Manage my group")
              }}
              secondaryAction={{
                to: "/library",
                children: tc("View included courses")
              }}
            />
          )
        } else {
          return (
            <StyledBlankSlate
              stack
              icon="subscription"
              heading={tc(`Your subscription to {{ school }} is active`, {
                school: school.name
              })}
              primaryAction={{
                to: "/library",
                children: tc("View included courses")
              }}
            />
          )
        }
      }
    } else {
      if (communityGroupWithActiveSubscription) {
        const group = communityGroupWithActiveSubscription
        const subscription = activeCommunityGroupSubscription
        return (
          <StyledBlankSlate
            icon="subscription"
            heading={tc(`You are subscribed through {{ group }}`, {
              group: group.name
            })}
            body={
              <>
                {tc(
                  `You have access to all courses included in {{ subscription }}`,
                  { subscription: subscription.price.product.name }
                )}
                .
                <br />
                <br />
                <Trans
                  i18nKey={
                    "If you'd like to change your subscription, please contact {{ owner }} at <a>{{ email }}</a>."
                  }
                  components={{
                    a: <a href={`mailto:${group.owner.email}`} />
                  }}
                  tOptions={{
                    owner: group.owner.full_name,
                    email: group.owner.email
                  }}
                />
              </>
            }
          />
        )
      } else {
        // Only prevent subscribing if user has an active membership (not revoked).
        const activeMembership = communityGroupMemberships.find((membership) =>
          hasActiveMembership(membership.group)
        )
        if (activeMembership) {
          const group = activeMembership.group
          return (
            <StyledBlankSlate
              icon="subscription"
              heading={tca(`Subscription Unavailable`)}
              body={
                <>
                  {tc(
                    `The {{ group }} subscription you are a part of isn't available`,
                    { group: group.name }
                  )}
                  .
                  <br />
                  <br />
                  <Trans
                    i18nKey="To resolve this issue, please contact the {{ group }} subscription owner, {{ owner }} at <a>{{ email }}</a>."
                    tOptions={{
                      group: group.name,
                      owner: group.owner.full_name,
                      email: group.owner.email
                    }}
                    components={{
                      a: <a href={`mailto:${group.owner.email}`} />
                    }}
                  />
                </>
              }
            />
          )
        }
      }
    }
  }

  if (schoolSubscriptionPlans.length <= 0 && stripeProducts.length <= 0) {
    return (
      <BlankSlate
        icon="subscription"
        heading={tc("No subscription plans found")}
      />
    )
  }

  if (selectedSubscriptionPlan) {
    if (!me) {
      return (
        <LazyAdaptedAuthContainer
          inviteToken={inviteToken}
          userPropertyDefaults={userPropertyDefaults}
        />
      )
    }
    return (
      <CardBlock>
        <Button
          brand
          styleType="text"
          onClick={() => setSelectedSubscriptionPlan(null)}
        >
          {tca("Change Plan")}
        </Button>
        <SubscriptionPlanOrderForm
          subscriptionPlan={selectedSubscriptionPlan}
          visibilityCode={visibilityCode}
          visibilityCodeFilter={visibilityCodeFilter}
          redirectTo={redirectTo}
        />
      </CardBlock>
    )
  }

  return (
    <StyledCardBlock>
      <div className="SubscriptionOptions">
        {stripeProducts.map((stripeProduct) => (
          <StripeProductCard
            key={stripeProduct.id}
            stripeProduct={stripeProduct}
            onAuthStep={setAuthStep}
            redirectTo={redirectTo}
            inviteToken={inviteToken}
          />
        ))}
        {!authStep && (
          <PositioningContextProvider
            items={schoolSubscriptionPlans}
            itemKey="subscription:plan"
            itemFragmentDocument={PositionedSchoolSubscriptionPlanFragmentDoc}
            context={positioningContext}
          >
            {schoolSubscriptionPlans.map((plan, index) => (
              <PositionedSubscriptionPlanCard
                key={plan.id}
                plan={plan}
                onSelectPlan={setSelectedSubscriptionPlan}
                showPlanDetails
                index={index}
              />
            ))}
          </PositioningContextProvider>
        )}
      </div>
    </StyledCardBlock>
  )
}

function SubscriptionPlanOrderForm({
  subscriptionPlan,
  visibilityCode,
  visibilityCodeFilter,
  redirectTo
}) {
  const apolloClient = useApolloClient()

  const [discountCode, setDiscountCode] = useState("")
  const [discount, setDiscount] = useState(null)

  const { tc } = useTranslate()

  const userSubscriptionQuery = useQuery(USER_SCHOOL_SUBSCRIPTION_QUERY)
  // when a 100% discount coupon is applied, we must bypass the order API and use the redeem API
  const [redeemSchoolGiftSubscription, { error }] = useMutation(
    REDEEM_SCHOOL_GIFT_SUBSCRIPTION,
    {
      variables: {
        school_subscription_plan_id: get(subscriptionPlan, "id"),
        code: discountCode
      },
      onCompleted: () => {
        // once subscription has been redeemed, route to confirmation
        // NOTE: redirectTo is always available as prop.
        try {
          // Get full URL.
          const schoolURL = new URL(getSchoolUrl(redirectTo))
          // Parse in the /thank-you/ route.
          const nextURL = new URL(
            getSchoolUrl(schoolURL.pathname, "/thank-you/", schoolURL.search)
          )
          // Navigate internally to the relative route
          window.App?.navigate(getRelativeUrl(nextURL.href))
          // Next, we reload the user's subscription, the expectation being
          // that this will trigger the next phase in whatever flow the SubscriptionPlanOrderForm
          // is a part of.
          // Reload legacy subscription store's user subscription.
          window.App?.getStore("subscribe").resource.subscription.load(
            {},
            true /* forceReload */
          )
          // Reload user's subscription in Apollo cache.
          userSubscriptionQuery.refetch()
        } catch {
          // We'll just have to simply reload the current URL, but it's unlikely
          // we'll make it here.
          window.location = redirectTo
        }
      }
    }
  )

  // NOTE: visibilityCodeFilter only supports eq right now.
  const planVisibilityCode =
    visibilityCode || (visibilityCodeFilter && visibilityCodeFilter.eq)

  return (
    <>
      <SubscriptionPlanCard plan={subscriptionPlan} />
      <br />
      <OrderDiscountForm
        applyDiscountCode={async (code) => {
          const discountQuery = await apolloClient.query({
            query: SCHOOL_SUBSCRIPTION_DISCOUNT_QUERY,
            variables: {
              discount_code: code,
              school_plan_id: subscriptionPlan.id
            },
            fetchPolicy: "network-only"
          })
          const discount = get(
            discountQuery,
            "data.schoolSubscriptionDiscount",
            null
          )
          setDiscount(discount)
          setDiscountCode(discount ? code : "")
          return discount
        }}
      />
      <br />
      {/* 100% off coupon where subscription plan does not require card info must be handled via redemption API */}
      {discount &&
      discount.discount_percent >= 1 &&
      subscriptionPlan.subscription_plan.trial_type !== 10 ? (
        <CenteredContent>
          <SubmitButton
            styleType="primary"
            size="large"
            onClick={() => redeemSchoolGiftSubscription()}
          >
            {tc("Redeem")}
          </SubmitButton>
        </CenteredContent>
      ) : (
        <OrderStripeCheckoutForm
          orderType={
            subscriptionPlan.subscription_plan.interval === 20
              ? "createLifetimeSchoolSubscriptionOrder"
              : "createSchoolSubscriptionOrder"
          }
          orderData={{
            plan_id: subscriptionPlan.id,
            plan_visibility_code: planVisibilityCode,
            coupon_code: discount && discountCode ? discountCode : null,
            redirect_to: redirectTo
          }}
        />
      )}
      {!!error && <Alert error={error} />}
    </>
  )
}

function SubscriptionPlanCard({ plan, showPlanDetails, onSelectPlan }) {
  const { t, tca } = useTranslate()
  const { currencyFormatter } = useCurrencyFormatter()

  return (
    <PlanCard key={plan.id}>
      <PlanHeader>
        <img src={plan.image} />
        <div>
          <Text.H4>{plan.subscription_plan.name}</Text.H4>
        </div>
      </PlanHeader>
      {plan.details_html && showPlanDetails ? (
        <StyledHTMLBody
          dangerouslySetInnerHTML={{ __html: plan.details_html }}
        />
      ) : (
        <br />
      )}
      <Text.Body>
        <b style={{ color: "rgb(42, 46, 51)" }}>
          {currencyFormatter.format(plan.subscription_plan.amount)}
        </b>{" "}
        {t("per")} {getIntervalDisplayName(plan.subscription_plan.interval, t)}
      </Text.Body>
      {onSelectPlan ? (
        <>
          <br />
          <Button styleType="primary" onClick={() => onSelectPlan(plan)} brand>
            {tca("Subscribe")}
          </Button>
        </>
      ) : null}
    </PlanCard>
  )
}

function PositionedSubscriptionPlanCard({
  plan,
  showPlanDetails,
  onSelectPlan,
  index
}) {
  const { me } = usePathwrightContext()
  const { updatePositionedObject } = usePositioningContext()

  // Only allow superusers initially.
  const canPosition = !!me?.permissions?.has_pathwright_staff_access && !!index

  function handleMoveUp() {
    updatePositionedObject({
      item: plan,
      index: index - 1
    })
  }

  return (
    <Box pos="relative">
      <SubscriptionPlanCard
        plan={plan}
        showPlanDetails={showPlanDetails}
        onSelectPlan={onSelectPlan}
      />
      {/* Only allow superusers initially. */}
      {canPosition && (
        <ChakraTooltip
          fitContent
          title="Move up (only visible to super users)."
        >
          <IconButton
            className="SchoolSubscriptionPlanReorder"
            aria-label="reorder"
            icon={<Pathicon icon="chevron-up" />}
            cursor="pointer"
            isRound={true}
            h={8}
            minW={8}
            onClick={handleMoveUp}
            pos="absolute"
            top={0}
            right={0}
            mt={2}
            mr={2}
          />
        </ChakraTooltip>
      )}
    </Box>
  )
}

const StyledHTMLBody = styled(Text.Body)`
  ul {
    list-style: unset;
    padding-left: 40px;
  }
`

const NarrowCard = styled(Card)`
  & > .View {
    max-width: 500px;
  }
  margin-bottom: 100px;
`

const PlanCard = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  position: relative;

  &:first-child {
    margin-top: 0;
    margin-bottom: 20px;

    .SchoolSubscriptionPlanReorder {
      display: none;
    }
  }

  &:not(:first-child) {
    margin: 10px 0 20px;
  }
`

const PlanHeader = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-column-gap: 0.5em;
  align-items: center;
  img {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
  }
`

const CenteredContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`

const StyledBlankSlate = styled(BlankSlate)`
  padding: 30px 40px 40px !important;
`
