import IconButton from "@pathwright/ui/src/components/button/IconButton"
import { useSessionStorage } from "@pathwright/ui/src/components/hooks/useLocalStorage"
import useOnClickOutside from "@pathwright/ui/src/components/hooks/useOnClickOutside"
import usePreviousEffect from "@pathwright/ui/src/components/hooks/usePreviousEffect"
import { media } from "@pathwright/ui/src/components/utils/styles"
import { useCallback, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { useFirebaseContext } from "../firebase/utils"
import { NotificationsFeed, filters } from "./NotificationsFeed"
import { useNotificationsState } from "./state/notification-state"

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  /* need extra margin on the right for the badge */
  margin: 0 10px;
  ${media.max.phone`position: static;`}
`

const NotificationsContainer = styled.div`
  width: 100vw;
  min-width: min(320px, 100%);
  max-width: 500px;
  background-color: rgba(255, 255, 255, 1);
  /* max-height: 500px; */
  border-radius: 16px;
  /* overflow: hidden; */
  position: absolute;
  top: 50px;
  right: 0px;
`

const NotificationsIndicator = () => {
  const { items, selected, unselect } = useNotificationsState()
  const [showNotifications, setShowNotifications] = useState(false)
  const [selectedFilter, setSelectedFilter] =
    useSessionStorage("feed-filters-key")

  // Set the selected feed filter to initial filter if new items are found,
  // as long as the feed isn't currently open.
  usePreviousEffect(
    ([prevItemsLength]) => {
      // Ensure prevItemsLength is greater than 0 to avoid resetting the filter
      // after inital laod of notifications, at the expense of missing the opportunity
      // to reset filter after new notifications have arrived when no notifications were
      // initially found.
      if (
        items.length > prevItemsLength &&
        prevItemsLength > 0 &&
        !showNotifications
      ) {
        if (selectedFilter !== filters[0].key) setSelectedFilter(filters[0].key)
      }
    },
    [items.length]
  )

  // Hide notifications list after a notification has been selected, since all this does
  // is perform a navigation to that notifiction's url.
  useEffect(() => {
    if (selected && selected.length) {
      unselect()
      setShowNotifications(false)
    }
  }, [selected])

  // Close selector when clicking outside container.
  const setNode = useOnClickOutside(
    useCallback(() => {
      if (showNotifications) setShowNotifications(false)
    }, [showNotifications])
  )

  const unreadCount = useMemo(() => {
    return items
      ? items
          .filter((item) => item.type == "item")
          .filter((item) => !item.data.read).length
      : 0
  }, [items])

  return (
    <Container ref={setNode}>
      <IconButton
        icon="bell"
        onClick={() => setShowNotifications(!showNotifications)}
        inverted
        styleType={showNotifications ? "primary" : "tertiary"}
        rounded={false}
        indicator={!!unreadCount}
      />
      {showNotifications && (
        <NotificationsContainer>
          <NotificationsFeed />
        </NotificationsContainer>
      )}
    </Container>
  )
}

const NotificationsIndicatorConnected = () => {
  // no reason to render this unless firebase is available
  const { isAuthenticated, app } = useFirebaseContext()
  if (!isAuthenticated || !app) return null

  return <NotificationsIndicator />
}

export default NotificationsIndicatorConnected
