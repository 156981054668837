import { ApolloClient, createHttpLink, from } from "@apollo/client"
import { ApolloErrorOptions, NetworkError } from "@apollo/client/errors"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { EnvType, getEnv } from "../lib/utils/env"
import cache from "./cache"
import { loadSpaceConfigBySubdomain } from "./config"

export const PW_AUTH_TOKEN_KEY = "auth_token"
export const PW_SPACE_KEY = "PW_SPACE"
export const PW_GQL_ENV_KEY = "PW_GQL_ENV"

declare global {
  interface Window {
    _testClient: ApolloClient<any>
  }
}

const clientEnvConfigs: Record<EnvType, { uri: URL["href"] }> = {
  test: {
    uri: "https://gql.pantswright.com/graphql"
  },
  testing: {
    uri: "https://gql.pantswright.com/graphql"
  },
  development: {
    uri: "http://localhost:8000/graphql"
  },
  staging: {
    uri: "https://gql.pathwrightstaging.com/graphql"
  },
  production: {
    uri: "https://gql.pathwright.com/graphql"
  }
}

const storage =
  typeof window !== "undefined" ? window.localStorage : { getItem: () => null }

export const getGraphQLEndpoint = () => {
  let envOverride = process.env.REACT_APP_GQL_ENV
  let gqlEnv = (storage.getItem(PW_GQL_ENV_KEY) ||
    envOverride ||
    getEnv()) as EnvType
  if (gqlEnv && clientEnvConfigs[gqlEnv]) {
    return clientEnvConfigs[gqlEnv].uri
  } else {
    throw new Error(`Invalid GQL env: ${gqlEnv}`)
  }
}

export const setSpace = (spaceId?: number) => {
  if (spaceId) {
    localStorage.setItem(PW_SPACE_KEY, spaceId.toString())
  } else {
    localStorage.removeItem(PW_SPACE_KEY)
  }
}

const fetchWithErrorRejection = async (
  uri: URL | RequestInfo,
  options?: RequestInit
) => {
  const response = await fetch(uri, options)
  if (!response.ok) {
    throw new Error("Network response was not ok")
  }
  return response
}

// this allows for auth/listing memberships before we know what the "local" space is
export const GLOBAL_SCHOOL_ID = 7888

const getCurrentSpace = () => {
  let value: any = localStorage.getItem(PW_SPACE_KEY) || GLOBAL_SCHOOL_ID
  // value must be a number
  if (value) {
    value = parseInt(value)
  } else {
    // clear the value
    console.warn("invalid space key found in local storage", value)
    localStorage.removeItem(PW_SPACE_KEY)
  }
  return value
}

export const CLIENT_ERROR_EVENT_KEY = "client-graphql-error"

export type ClientErrorDetail = {
  graphQLErrors: ApolloErrorOptions["graphQLErrors"] | undefined
  networkError: NetworkError | undefined
}

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    // Removed stored auth_token when invalid.

    console.log("graphQLErrors", graphQLErrors)
    if (
      graphQLErrors &&
      graphQLErrors.find(
        ({ message }) =>
          message ===
            `Invalid token. An error occurred while verifying the token: The provided token was revoked.` ||
          message ===
            `Invalid token. An error occurred while verifying the token: The provided token could not be found in the database.`
      ) &&
      localStorage.getItem("auth_token")
    ) {
      localStorage.removeItem("auth_token")
      return forward(operation)
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      )
    }
    if (networkError) console.log(`[Network error]: ${networkError}`)

    // NOTE: since we only use the web-new package for authentication, we don't want to
    // arbitrarily reset to the GLOBAL_SCHOOL_ID due to a network error. User could merely
    // have poor network connectivity resulting in switching the space to our own and then
    // subsequent requests go through the GLOBAL_SCHOOL_ID space rather than the one connected
    // to the url the user is on.
    // if (!graphQLErrors && networkError) {
    //   // Likely an invalid space, so switch to the `GLOBAL_SCHOOL_ID`.
    //   if (getCurrentSpace() !== GLOBAL_SCHOOL_ID) {
    //     setSpace(GLOBAL_SCHOOL_ID)
    //     // NOTE: this currently doesn't work as the same space ID is used in the subsequent query.
    //     return forward(operation)
    //   }
    // }

    const detail: ClientErrorDetail = {
      graphQLErrors,
      networkError
    } as ClientErrorDetail

    if (typeof window !== "undefined") {
      window.dispatchEvent(new CustomEvent(CLIENT_ERROR_EVENT_KEY, { detail }))
    }
  }
)

let _spaceId: number
export const shouldUseSubdomainUrls = () => {
  let hostname = window.location.hostname
  // are we running on paths.app or pathwrightdev.com?
  return (
    hostname.includes("paths.app") || hostname.includes("pathwrightdev.com")
  )
}

const lookupSpaceConfigBySubdomain = () => {
  const subdomain = window.location.hostname.split(".")[0]
  return loadSpaceConfigBySubdomain(subdomain)
}

const setSpaceLink = setContext(async () => {
  if (shouldUseSubdomainUrls() && !_spaceId) {
    const spaceConfig = await lookupSpaceConfigBySubdomain()
    if (spaceConfig) {
      _spaceId = spaceConfig.id
      setSpace(_spaceId)
    }
  } else {
    _spaceId = getCurrentSpace()
  }
  return {
    headers: {
      school_id: _spaceId
    }
  }
})

let _client: any = null
export const getClient = (reset = false): ApolloClient<any> => {
  if (_client && reset) {
    _client = null
  }

  // If in "test" env, attempt to use the window._testClient
  if (getEnv("test") && window._testClient && _client !== window._testClient) {
    _client = window._testClient
  }

  if (!_client) {
    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      const token = localStorage.getItem(PW_AUTH_TOKEN_KEY)

      let nextHeaders = { ...headers }

      if (token) {
        nextHeaders.authorization = `Bearer ${token}`
      }
      // nextHeaders.school_id = getCurrentSpace()
      // return the headers to the context so httpLink can read them
      return {
        headers: nextHeaders
      }
    })

    const getEndpointURI = () => {
      return `${getGraphQLEndpoint()}?school_id=${getCurrentSpace()}`
    }

    const httpLink = createHttpLink({
      uri: getEndpointURI,
      fetch: fetchWithErrorRejection
    })

    _client = new ApolloClient({
      link: from([setSpaceLink, authLink, errorLink, httpLink]),
      cache,
      name: "pathwright",
      version: "3.0"
    })
  }

  return _client
}
