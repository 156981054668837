const { getOrCreateResourceCache } = require("./resource-cache")

const {
  isModel,
  isResourceModel,
  isCollection,
  isResourcePaginatedCollection,
  isPaginatedCollection
} = require("./utils")
const { API } = require("lib/core/api/request")

const createResourceLoader = function (Resource, resourceKey, cacheResource) {
  if (cacheResource == null) {
    cacheResource = true
  }
  const resourceLoader = function (
    params,
    forceReload,
    ignoreCache,
    abortControl
  ) {
    let cache
    if (forceReload == null) {
      forceReload = false
    }
    if (cacheResource) {
      cache = getOrCreateResourceCache(resourceKey)
    } else {
      // Always force reload and ignore cache when resource is not cached.
      forceReload = true
      ignoreCache = true
    }

    if (
      cacheResource &&
      cache.isResourceCached(params) &&
      !forceReload &&
      !ignoreCache
    ) {
      return cache.getResource(params) // the cached value is a promise
    } else {
      let resourcePromise = new Promise(function (resolve, reject) {
        let resource
        if (isModel(Resource)) {
          resource = new Resource(params)
        } else if (isCollection(Resource)) {
          resource = new Resource([], params) // assuming the collection uses @options for params
        } else {
          console.log("Non Model Passed!", Resource, typeof Resource, params)
          // console.trace()
          throw new Error(
            "Resource loaders can only fetch Backbone Models or Collections. You passed ",
            Resource
          )
        }

        return API.get(resource.url(), { signal: abortControl() })
          .then((data) => {
            if (isModel(Resource)) {
              resource.set(resource.parse(data))
            } else if (
              isPaginatedCollection(Resource) ||
              isCollection(Resource)
            ) {
              resource.add(resource.parse(data))
            }
            resolve(resource)
          })
          .catch(function (err) {
            if (cache != null) {
              cache.clearAll()
            }
            return reject(err)
          })
      })

      if (cacheResource) {
        cache.setResource(params, resourcePromise)
        resourcePromise = cache.getResource(params)
      }

      return resourcePromise
    }
  }

  resourceLoader._resourceKey = resourceKey
  resourceLoader.Resource = Resource
  resourceLoader.cacheResource = cacheResource
  return resourceLoader
}

const getMutationData = function (mutations, key, value) {
  if (_.isString(key)) {
    mutations[key] = value
  } else if (_.isFunction(key)) {
    mutations = _.assign({}, mutations, key(value))
  } else if (_.isObject(key)) {
    mutations = _.assign({}, mutations, key)
  }
  return mutations
}

const getPaginationData = function (collection) {
  const { page_size, page } = collection.options
  const { count } = collection
  const remaining = count - page_size * page
  const has_next = remaining > 0
  return {
    page_size,
    page,
    has_next,
    count,
    remaining
  }
}

const getPaginationMutationData = function (resource, resource_key) {
  let update = {}

  if (isResourcePaginatedCollection(resource)) {
    // This collection has extra paginated data, store it in it's own key,
    // TODO: make this part of keys?
    const pagination_key = `${resource_key}_pagination`
    update = getMutationData(
      update,
      pagination_key,
      getPaginationData(resource)
    )

    if (resource.context) {
      update = getMutationData(
        update,
        `${resource_key}_context`,
        resource.context
      )
    }
  }

  return update
}

const createResourceLoadAction = function (
  store,
  resourceLoader,
  resource_key,
  loading_key,
  error_key,
  loaded_key,
  controller_key
) {
  const mutate = store._mutate.bind(store)

  const loadAction = function (params, forceReloadOrOptions, ignoreCache) {
    // Allow for using second arg to loadAction for passing an options object which
    // can contain both forceReload and ignoreCache. This should have been the original
    // approach, expecting an object rather than multiple args, hence this alternate option.
    let forceReload =
      typeof forceReloadOrOptions === "object"
        ? forceReloadOrOptions.forceReload
        : forceReloadOrOptions
    ignoreCache =
      typeof forceReloadOrOptions === "object"
        ? forceReloadOrOptions.ignoreCache
        : ignoreCache

    // Hack to correctly cache collections that augment their options
    if (isCollection(resourceLoader.Resource)) {
      params = new resourceLoader.Resource([], params).options
    }

    if (forceReload == null) {
      forceReload = false
    }
    // Always force reload and ignore cache when resource is not cached.
    if (!resourceLoader.cacheResource) {
      forceReload = true
      ignoreCache = true
    }
    const actionData = {
      action: `${resource_key}.load`,
      args: arguments
    }
    store._logAction(actionData)

    let cache = getOrCreateResourceCache(resourceLoader._resourceKey)

    // Aborts previous fetch for store resource and supplies next controller/signal next fetch
    const abortControl = () => {
      let controller = null
      // Abort current request if resource is currently loading.
      // Use store.previous() since the key will have just changed
      if (store.previous(loading_key) && store.get(controller_key)) {
        store.get(controller_key).abort()
      }
      // Set AbortController if available
      if (window.AbortController) {
        controller = new AbortController()
        mutate({ [controller_key]: controller })
      }
      return controller && controller.signal
    }

    const cachedResourceLoaderPromise = cache?.getResource?.(params)
    const resourceLoaderPromise = resourceLoader(
      params,
      forceReload,
      ignoreCache,
      abortControl
    )

    // If promises are unequal, then we know we're actually issuing a network request
    // so let's set the loading keys.
    const isLoading = cachedResourceLoaderPromise !== resourceLoaderPromise
    if (isLoading) {
      const update = {}
      update[loaded_key] = false
      update[loading_key] = true
      // If this is a force reload, force the resource key to start null
      if (forceReload === true) {
        update[resource_key] = null
      }
      mutate(update)
    }

    resourceLoaderPromise
      .then(function (resource) {
        let update = getMutationData({}, loading_key, false)

        if (resource_key) {
          update = getMutationData(update, resource_key, resource)
        }

        update = getMutationData(
          update,
          getPaginationMutationData(resource, resource_key)
        )

        update[loaded_key] = true
        update[error_key] = null
        mutate(update)

        // Setup backbone store triggers to fire when change triggers
        // fire on the model/collection. This ensures the backbone store
        // reserializes this resource when any of its attributes or children's
        // attributes (in the case of a collection) changes.
        resource.on("change update", () => {
          store.triggerChange(`change:${resource_key}`)
        })

        return resource
      })
      .catch(function (err) {
        let update = getMutationData({}, loading_key, false)
        if (err.status != null && error_key) {
          // only mutate error keys if it's an http error
          update = getMutationData(update, error_key, err)
        }
        mutate(update)
        if (err.status == null) {
          console.error(
            `${resource_key} resource load action runtime error: `,
            err
          )
        }
        // console.trace()
        return cache != null ? cache.clearAll() : undefined
      })

    // NOTE: does the code below for cache.setResource when not ignoring
    // cache actually improve anything? The resourceLoader already handles
    // cache.setResource when necessary.

    // We can cache the promise which will allow subsequent resource loader calls
    // to use the same promise/result, bypassing the need to abort the previous call.
    // if (!ignoreCache) {
    //   cache.setResource(params, resourceLoaderPromise)
    // }

    return resourceLoaderPromise
  }
  // else
  //   console.log("http error recieved", err)
  // throw new Error(err)

  return loadAction
}

export { createResourceLoadAction, createResourceLoader, getPaginationData }
